<!-- src/views/NotFound.vue -->
<template>
  <div>
    <h1>404 - Página No Encontrada</h1>
    <p>Lo sentimos, la página que estás buscando no existe.</p>
    <router-link to="/verif-carpinclub"
      >Volver a la página principal</router-link
    >

    <!-- <div class="pregresp">
        <div class="pregunta">¿Requiere disponibilidad para viajar?</div>
        <br>
        <div class="respuestas">
            <input type="radio" name="viajar" id="viajar_si" value="Si" required />
            <label for="viajar_si">Sí</label>
            <div class="respuesta-separada"></div>
            <input type="radio" name="viajar" id="viajar_no" value="No" required />
            <label for="viajar_no">No</label>
        </div>
    </div> -->
  </div>
</template>

<script>
export default {
  mounted () {
    // const storedUserData = JSON.parse(localStorage.getItem('userData'))
    // this.userData = storedUserData
    // console.log(this.userData)
    // // this.cedula = localStorage.getItem('userData')
    // this.userRole = this.userData.cargo
    console.log(this.userRole)
  },
  data () {
    return {
      userRole: localStorage.getItem('userRole')
    }
  },
  name: 'NotFound'
}
</script>

<style scoped>
/* Añade estilos específicos para la página de error si es necesario */
</style>
