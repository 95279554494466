<template>
  <div class="verify-phone">
    <h1 class="tipo_letra mb-4">Verificación de celular</h1>
    <div v-if="loading" class="tipo_letra text-center">
      <div class="tipo_letra spinner-border text-primary" role="status">
        <span class="tipo_letra visually-hidden">Cargando...</span>
      </div>
      <p class="tipo_letra mt-2">Cargando...</p>
    </div>
    <div v-else class="tipo_letra alert" :class="{ 'alert-success': !registro, 'alert-warning': registro }">
      <p v-if="registro === false" class="tipo_letra mb-0">
        ¡Excelente! Tu número ha sido verificado. Ahora vamos a confirmar tu correo para finalizar.
      </p>
      <p v-if="registro === true" class="tipo_letra mb-0">
        Este número telefónico ya se encuentra registrado y verificado.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      celular: '',
      token: '',
      registro: null,
      loading: true
    }
  },
  created () {
    this.celular = this.$route.params.celular
    this.token = this.$route.params.token
    this.ejecutarFuncion(this.celular, this.token)
  },
  methods: {
    ejecutarFuncion (celular, token) {
      console.log('celular recibido:', celular)
      console.log('token recibido:', token)
      const formData = {
        token1: celular,
        token2: token
      }
      fetch('https://app.carpincentro.com/funciones_carpinclub/validarcelular.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('Respuesta del servidor:', data)
          this.registro = data.Listo === 'Ya esta registrado'
          this.loading = false
        })
        .catch((error) => {
          console.error('Error al actualizar los datos:', error)
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>
.verify-phone {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  color: var(--primary-color);
}

.spinner-border {
  width: 3rem;
  height: 3rem;
}

.tipo_letra{
  font-family: 'Futura'
}
</style>
