import { createRouter, createWebHistory } from 'vue-router'
import VerificaCarpinClub from '@/views/VerificaCarpinClub.vue'
import VerificaCarpinClub1 from '@/views/VerificaCarpinClub1.vue'
import VerificaCarpinClubCelular from '@/views/VerificaCarpinClubCelular.vue'
import VerificaCarpinClubEmail from '@/views/VerificaCarpinClubEmail.vue'
import NotFound from '@/views/NotFound.vue'

const routes = [
  {
    path: '/verif-carpinclub',
    name: 'VerificaCarpinClub',
    component: VerificaCarpinClub
  },
  {
    path: '/verif-carpinclub/datos',
    name: 'VerificaCarpinClub1',
    component: VerificaCarpinClub1
  },
  {
    path: '/verif-carpinclub/cel/:celular/:token',
    name: 'VerificaCarpinClubCelular',
    component: VerificaCarpinClubCelular
  },
  {
    path: '/verif-carpinclub/eml/:email/:token',
    name: 'VerificaCarpinClubEmail',
    component: VerificaCarpinClubEmail
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(),
  mode: 'history',
  routes
})

// router.beforeEach((to, from, next) => {
//   const userRole = localStorage.getItem('userRole')
//   const isAuthenticated = localStorage.getItem('loggedin')

//   if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
//     next({
//       path: '/login',
//       query: { redirect: to.fullPath }
//     })
//   } else if (to.matched.some(record => record.meta.roles)) {
//     const requiredRoles = to.meta.roles
//     console.log('requiredRoles')
//     console.log(requiredRoles)
//     if (requiredRoles.includes(userRole)) {
//       next()
//     } else {
//       next({
//         path: '/login',
//         query: { redirect: to.fullPath }
//       })
//     }
//   } else {
//     next()
//   }
// })

export default router
