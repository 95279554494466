<template>
  <div id="app">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="class=logo-container">
        <a class="navbar-brand" href="#">
          <img src="https://ardisa.com/pub/media/firmas/logofirmacarpincentro.png" alt="CarpinClub Logo" class="responsive-logo">
          <!-- CarpinClub -->
        </a>
      </div>
    </nav>

    <main class="container py-4">
      <router-view />
    </main>

    <footer class="footer mt-auto py-3 bg-light">
      <div class="container text-center">
        <span class="text-muted"> 2024 CarpinClub. Todos los derechos reservados.</span>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
.verify-carpin-club {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

/* Estilos para el contenedor del logo */
.logo-container {
  text-align: center;
  margin-bottom: 20px;
}

/* Estilos para hacer el logo responsive */
.responsive-logo {
  max-width: 100%;
  height: auto;
  max-height: 100px; /* Ajusta este valor según tus necesidades */
}

/* Media query para ajustar el tamaño en pantallas más pequeñas */
@media (max-width: 768px) {
  .responsive-logo {
    max-height: 80px; /* Tamaño más pequeño para pantallas móviles */
  }
}

:root {
  --primary-color: #1d2951;
  --secondary-color: #fae800;
}

body {
  font-family: 'futura';
  color: var(--primary-color);
}

.navbar-brand {
  color: var(--primary-color) !important;
}

.btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.btn-primary:hover {
  background-color: #161f3d;
  border-color: #161f3d;
}

.btn-outline-primary {
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.btn-outline-primary:hover {
  background-color: var(--primary-color);
  color: white;
}

.text-primary {
  color: var(--primary-color) !important;
}

.bg-primary {
  background-color: var(--primary-color) !important;
}
</style>
