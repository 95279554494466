<template>
  <div class="verify-phone">
    <h1 class="mb-4">Datos enviados</h1>
    <div class="alert" :class="{ 'alert-success': !registro, 'alert-warning': registro }">
      <p class="mb-0">
        ¡Gracias! Ya casi terminamos. Recibirás un mensaje de texto para verificar tu celular. Estos últimos pasos son muy importantes para proteger tu cuenta y acceder a todos los beneficios de CarpinClub.
      </p>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>
.verify-phone {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  color: var(--primary-color);
}

.spinner-border {
  width: 3rem;
  height: 3rem;
}
</style>
